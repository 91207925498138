<template>
  <label class="form-label" for="selectVkBinding">Отправить от группы:</label>
  <div class="pb-3">
    <select id="selectVkBinding" class="form-select" v-model="localNewMailingLetters.mailingLetters.vkBindingId"
            @change="resetInitUploadImageVkModal" :disabled="readOnly">
      <!--suppress JSUnresolvedVariable -->
      <option :value="null" disabled>
        Выберите группу...
      </option>
      <option v-for="item in localNewMailingLetters.vkBindings" :value="item.id">
        {{ item.name }}
      </option>
    </select>
  </div>

  <div class="pb-3">
    <!--suppress XmlInvalidId -->
    <label class="form-label" for="vkText">Текст для ВКонтакте:</label>
    <markdown-wysiwyg
        :id="'vkText'"
        :links="localNewMailingLetters.vkLetterLinks"
        :variables="variablesVkText"
        v-model:model-value="localNewMailingLetters.mailingLetters.vkText"
        :disabled="readOnly"
        :readonly="readOnly"
        :maxlength="3277"
        type="vk"
    />
    <label class="form-text">
      Переходы считаются только по ссылкам, вставленным через форму, и по кнопке внизу письма.
    </label>
  </div>

  <label class="form-label">Ссылки в письме:</label>

  <div class="row-form">
    <div v-for="item in localNewMailingLetters.vkLetterLinks" class="row">
      <div class="col-sm pb-2">
        <input-url-utp1
            :placeholder="'URL ссылки'"
            :required="true"
            :disabled="readOnly"
            :readonly="readOnly"
            v-model:url="item.linkUrl"
        />
      </div>
      <div class="col-sm-4 col pb-3">
        <input-utp1
            v-model:model-value="item.linkText"
            placeholder="Текст ссылки"
            type="text"
            minlength="1"
            maxlength="255"
            autocomplete="off"
            :disabled="readOnly"
            :readonly="readOnly"
        />
      </div>
      <div class="col-sm-2 col-auto pb-2">
        <input type="text" :id="'copyVkLink' + item.letterLinkId" readonly
               :value="'[#link' + item.letterLinkId + '()]'"
               class="form-control-plaintext mailing-letter-code text-center small cursor-pointer"
               @click="copyTextFunction('copyVkLink' + item.letterLinkId)">
      </div>
      <div class="col-auto pb-2">
        <button type="button" class="btn btn-outline-danger rounded-circle" :disabled="readOnly"
                @click="deleteVkLettersLinks(item.letterLinkId)">
          <i class="bi bi-x-lg mx--125"></i>
        </button>
      </div>
    </div>
  </div>
  <label v-if="localNewMailingLetters.vkLetterLinks.length > 0"
         class="form-text pb-2" style="margin-top: -0.5rem">
    Обращаем Ваше внимание! Удаление ссылки может привести к изменению индексов других ссылок.
    При этом из текста письма ссылки с несуществующими индексами автоматически удаляться не будут.
  </label>
  <div class="pb-3">
    <button-primary-utp1 @buttonClick="addVkLettersLinks" :disabled="readOnly">
      Добавить ссылку
    </button-primary-utp1>
  </div>

  <label class="form-label">Изображения для ВКонтакте:</label>

  <div class="row-form-s">
    <div class="row">
      <div v-for="item in localNewMailingLetters.vkLetterImages"
           class="col-6 col-sm-4 col-md-2 col-lg-3 col-xl-2 pb-3 pt-2">
        <div class="ratio ratio-4x3 text-center">
          <span>
            <img :src="item.url" alt="VkLetterImage" class="mw-100 mh-100">
            <button class="position-absolute translate-middle badge btn btn-danger rounded-circle" :disabled="readOnly"
                    @click="deleteVkLettersImages(item.id)">
              <i class="bi bi-x-lg mx--2255"></i>
            </button>
          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="pb-3">
    <button-primary-utp1
        @buttonClick="addVkLettersImages"
        :disabled="readOnly || localNewMailingLetters.vkLetterImages.length > 5 ||
        localNewMailingLetters.mailingLetters.vkBindingId === null">
      Добавить изображение
    </button-primary-utp1>
    <div
        v-if="localNewMailingLetters.vkLetterImages.length > 5 || localNewMailingLetters.mailingLetters.vkBindingId === null">
      <label class="form-text" v-if="localNewMailingLetters.vkLetterImages.length > 5">
        Максимум можно добавить 6 изображений
      </label>
      <label class="form-text" v-if="localNewMailingLetters.mailingLetters.vkBindingId === null">
        Изображения можно добавить только если выбрана группа
      </label>
    </div>
  </div>

  <div class="row-form">
    <div class="row">
      <label class="form-label">Кнопка внизу для ВКонтакте:</label>
      <div class="col-sm-6 pb-2">
        <input-url-utp1 v-model:url="localNewMailingLetters.mailingLetters.vkActionButtonUrl"
                        :placeholder="'URL кнопки'"
                        :disabled="readOnly" :readonly="readOnly"/>
      </div>
      <div class="col-sm-6">
        <input-utp1
            v-model:model-value="localNewMailingLetters.mailingLetters.vkActionButtonText"
            placeholder="Текст кнопки"
            type="text"
            minlength="1"
            maxlength="40"
            autocomplete="off"
            :disabled="readOnly"
            :readonly="readOnly"
        />
      </div>
      <label class="form-text mt-0">
        Будьте осторожны при использовании переменных! Максимальная длина текста кнопки 40 символов.
      </label>
    </div>
  </div>

  <!--suppress JSValidateTypes -->
  <upload-image-vk-modal
      :letter-image-id="vkLetterImageId"
      :mailing-type="'SINGLE'"
      :init="initUploadImageVkModal"
      :vk-binding-id="localNewMailingLetters.mailingLetters.vkBindingId"
      :vk-binding-name="vkBindingName"
      @uploadImageVkMailing="uploadImageVkMailing"
  />

</template>

<!--suppress JSUnresolvedReference -->
<script>
import MarkdownWysiwyg from "../../UI/inputs/MarkdownWysiwyg";
import InputUrlUtp1 from "../../UI/inputs/InputUrlUtp1";
import ButtonSecondaryUtp1 from "../../UI/buttons/ButtonSecondaryUtp1";
import ButtonPrimaryUtp1 from "../../UI/buttons/ButtonPrimaryUtp1";
import UploadImageVkModal from "../../UI/modals/UploadImageVkModal";
import {ref, onMounted, watch} from "vue";
import InputUtp1 from "../../UI/inputs/InputUtp1";

export default {
  name: "VkLetter",
  components: {InputUtp1, UploadImageVkModal, ButtonPrimaryUtp1, ButtonSecondaryUtp1, InputUrlUtp1, MarkdownWysiwyg},
  props: {
    newMailingLetters: {
      mailingLetters: {},
      mailingLetterLinks: [],
      mailingLetterImages: [],
      vkLetterLinks: [],
      vkLetterImages: [],
      emailBindings: [],
      vkBindings: []
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.localNewMailingLetters = this.newMailingLetters
  },
  watch: {
    newMailingLetters() {
      this.localNewMailingLetters = this.newMailingLetters
    }
  },
  emits: ['update:newMailingLetters'],
  setup(props, {emit}) {
    const localNewMailingLetters = ref({
      mailingLetters: {},
      mailingLetterLinks: [],
      mailingLetterImages: [],
      vkLetterLinks: [],
      vkLetterImages: [],
      emailBindings: [],
      vkBindings: []
    })

    watch(localNewMailingLetters, (newVal) => {
      emit('update:newMailingLetters', newVal)
    })

    const variablesVkText = [{
      name: 'Имя',
      value: '[#name()]'
    }, {
      name: 'Контакт',
      value: '[#contact()]'
    }, {
      name: 'Город',
      value: '[#city()]'
    }, {
      name: 'Страна',
      value: '[#country()]'
    }, {
      name: 'Дата рождения',
      value: '[#birthday()]'
    }, {
      name: 'Автор',
      value: '[#author()]'
    }]
    const initUploadImageVkModal = ref(false)
    const vkLetterImageId = ref(null)
    const vkBindingName = ref("")
    const uploadImageVkModal = ref(null)

    onMounted(() => {
      uploadImageVkModal.value = new bootstrap.Modal('#uploadImageVkModal', {})

      const modal = document.getElementById("uploadImageVkModal");
      modal?.addEventListener("hide.bs.modal", () => {
        document.activeElement?.blur(); // Снять фокус перед скрытием
      })
    })

    const addVkLettersLinks = () => {
      localNewMailingLetters.value.vkLetterLinks.push({
        letterLinkId: localNewMailingLetters.value.vkLetterLinks.length,
        linkUrl: "",
        linkText: ""
      })
    }

    const deleteVkLettersLinks = (letterLinkId) => {
      localNewMailingLetters.value.vkLetterLinks.splice(letterLinkId, 1)
      localNewMailingLetters.value.vkLetterLinks.forEach(item => {
        item.letterLinkId = localNewMailingLetters.value.vkLetterLinks.indexOf(item)
      })
    }

    const copyTextFunction = (id) => {
      const copyText = document.getElementById(id);
      copyText.select();
      navigator.clipboard.writeText(copyText.value)
    }

    const addVkLettersImages = () => {
      vkLetterImageId.value = localNewMailingLetters.value.vkLetterImages.length
      if (initUploadImageVkModal.value === false) {
        localNewMailingLetters.value.vkBindings.forEach(item => {
          if (item.id === localNewMailingLetters.value.mailingLetters.vkBindingId) {
            vkBindingName.value = item.name
          }
        })
      }
      initUploadImageVkModal.value = true
      uploadImageVkModal.value.show()
    }

    const resetInitUploadImageVkModal = () => {
      initUploadImageVkModal.value = false
    }

    const uploadImageVkMailing = (vkMessagesPhoto) => {
      localNewMailingLetters.value.vkLetterImages.push(vkMessagesPhoto)
      uploadImageVkModal.value.hide()
    }

    const deleteVkLettersImages = (id) => {
      localNewMailingLetters.value.vkLetterImages.splice(id, 1)
      localNewMailingLetters.value.vkLetterImages.forEach(item => {
        item.id = localNewMailingLetters.value.vkLetterImages.indexOf(item)
      })
    }

    return {
      localNewMailingLetters,
      variablesVkText,
      addVkLettersLinks,
      deleteVkLettersLinks,
      copyTextFunction,
      initUploadImageVkModal,
      resetInitUploadImageVkModal,
      vkLetterImageId,
      vkBindingName,
      addVkLettersImages,
      uploadImageVkMailing,
      deleteVkLettersImages
    }
  }
}
</script>

<style scoped>
.mx--125 {
  margin-right: -0.125rem !important;
  margin-left: -0.125rem !important;
}

.mx--2255 {
  margin-right: -0.2255rem !important;
  margin-left: -0.2255rem !important;
}

.mailing-letter-code {
  width: 70px;
  color: #842029;
  background-color: #f8d7da;
}
</style>