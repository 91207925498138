<template>
  <preloader-modal v-if="mailingVkLoader"/>
  <div class="modal fade" id="uploadImageVkModal" tabindex="-1">
    <div class="modal-dialog modal-xl modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Изображения сообщества «{{ vkBindingName }}»</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body row-form">
          <div class="row">
            <div class="col-7 col-sm-8 col-lg-9 col-xl-10">
              <div class="row-form-s">
                <div class="row">
                  <div v-if="errorMessage" class="col-12">
                    <alert-danger>{{ errorMessage }}</alert-danger>
                  </div>
                  <div class="col-sm pb-2 pb-sm-0">
                    <form id="imageVkModalForm" @submit.prevent novalidate class="col ">
                      <input class="form-control" type="file" id="imageVkModal" ref="newVkMessagesPhotos"
                             @change="imagesUpload" accept="image/*" required>
                    </form>
                  </div>
                  <div class="col-sm-auto">
                    <button-primary-utp1 @buttonClick="addVkMessagesPhoto">Загрузить</button-primary-utp1>
                  </div>
                  <div class="col-12 pb-2">
                    <label class="form-text">
                      Сумма высоты и ширины не более 14 000px, соотношение сторон не менее 1:20, максимальный размер
                      20mb.
                    </label>
                  </div>
                </div>
              </div>
              <div class="pb-3">Для доступа к фотографиям другого сообщества необходимо его выбрать в поле «Отправить от
                группы»
              </div>

              <div class="row-form-s">
                <div class="row">
                  <div v-for="item in listVkMessagesPhoto.vkMessagesPhotos"
                       class="col-6 col-sm-4 col-lg-3 col-xl-2 pb-3">
                    <input class="d-none"
                           type="checkbox"
                           :value="item.id"
                           :id="item.id"
                           @change="checkImg(item.id)">
                    <div class="ratio ratio-1x1 text-center">
                      <label :for="item.id"
                             :class="item.id === selectedImg.id ? '' : 'border-white'"
                             class="cursor-pointer upload-image-hover p-1 border">
                        <img
                            :src="item.url"
                            style="max-width: 100%; max-height: 100%" alt="ImageMailing">
                      </label>
                    </div>

                  </div>
                  <pagination-light-utp1
                      v-model:page="page"
                      :total="listVkMessagesPhoto.number"
                  />
                </div>
              </div>
            </div>
            <div class="col-5 col-sm-4 col-lg-3 col-xl-2">
              <div v-if="selectedImg.id" class="pb-3 text-center">
                <img class="pb-2"
                     :src="selectedImg.url"
                     style="max-width: 100%; max-height: 164px" alt="ImageMailing">
                <button-primary-utp1 class="d-sm-grid pb-2" @buttonClick="uploadImageMailing">Выбрать
                </button-primary-utp1>
                <button-danger-utp1 class="d-sm-grid" @buttonClick="deleteVkMessagesPhoto">Удалить</button-danger-utp1>
              </div>
              <div class="pb-3">
                <label class="pb-2">Тип рассылки:</label>
                <select class="form-select" v-model="filter.mailing_types">
                  <option selected value="">Любая</option>
                  <option v-for="item in mailingTypes" :value="item.value">
                    {{ item.name }}
                  </option>
                </select>
              </div>
              <button-secondary-utp1 @buttonClick="getVkMessagePhotos" class="d-sm-grid">Искать
              </button-secondary-utp1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<!--suppress JSCheckFunctionSignatures -->
<script>
import ButtonPrimaryUtp1 from "../buttons/ButtonPrimaryUtp1";
import ButtonSecondaryUtp1 from "../buttons/ButtonSecondaryUtp1";
import {ref, watch} from "vue";
import ButtonDangerUtp1 from "../buttons/ButtonDangerUtp1";
import PaginationLightUtp1 from "../paginations/PaginationLightUtp1";
import {useRoute, useRouter} from "vue-router";
import PreloaderMain from "../../system/preloader/PreloaderMain";
import PreloaderModal from "../../system/preloader/PreloaderModal";
import AlertDanger from "../alerts/AlertDanger";
import useError from "../../../hooks/error/useError";
import useMailingVk from "../../../hooks/rest/mailing/useMailingVk";

export default {
  name: "upload-image-vk-modal",
  components: {
    AlertDanger,
    PreloaderModal,
    PreloaderMain, PaginationLightUtp1, ButtonDangerUtp1, ButtonSecondaryUtp1, ButtonPrimaryUtp1
  },
  props: {
    letterImageId: {
      type: Number
    },
    mailingType: {
      type: String
    },
    init: {
      type: Boolean
    },
    vkBindingId: {
      type: Number
    },
    vkBindingName: {
      type: String
    }
  },
  watch: {
    init(newVal) {
      if (newVal === true) {
        this.initUploadImageMailingModal()
      }
    }
  },
  emits: ["uploadImageVkMailing"],
  setup(props, {emit}) {
    const route = useRoute()
    const router = useRouter()
    const newVkMessagesPhotos = ref(null)
    const vkMessagesPhotos = ref(null)

    const mailingTypes = [{
      value: "SINGLE",
      name: "Разовая"
    }, {
      value: "AUTO",
      name: "Автоматическая"
    }, {
      value: "LAUNCH",
      name: "Запуск"
    }]

    const filter = ref({
      mailing_type: props.mailingType,
      mailing_types: route.query.mailing_types ? route.query.mailing_types : "",
    })

    const page = ref(Number(route.query.page) ? Number(route.query.page) : 1)

    const myQuery = () => {
      return {
        mailing_type: filter.value.mailing_type,
        mailing_types: filter.value.mailing_types,
        page: page.value
      }
    }

    watch(filter.value, () => {
      router.replace({
        query: myQuery()
      })
    })

    watch(page, async () => {
      await router.replace({
        query: myQuery()
      })
      await getVkMessagesPhotos(props.vkBindingId)
    })

    const {
      mailingVkLoader,
      mailingVkException,
      listVkMessagesPhoto,
      getVkMessagesPhotos,
      addVkMessagesPhotos,
      deleteVkMessagesPhotos
    } = useMailingVk()

    const getVkMessagePhotos = () => {
      if (page.value === 1) {
        getVkMessagesPhotos(props.vkBindingId)
      } else page.value = 1
    }

    const {error, errorMessage} = useError()

    watch(mailingVkException, () => {
      error(mailingVkException.value)
    })

    const initUploadImageMailingModal = async () => {
      await router.replace({
        query: myQuery()
      })
      await getVkMessagesPhotos(props.vkBindingId)
    }

    const imagesUpload = () => {
      vkMessagesPhotos.value = newVkMessagesPhotos.value.files[0]
    }

    const addVkMessagesPhoto = async () => {
      let formData = new FormData()
      formData.append('file', vkMessagesPhotos.value)
      const form = document.querySelector('#imageVkModalForm')
      if (!form.checkValidity()) {
        form.classList.add("was-validated")
      } else {
        resetSelectedImg()
        page.value = 1
        const vkMessagesPhoto = await addVkMessagesPhotos(formData, props.vkBindingId)
        if (!mailingVkException.value) {
          emit('uploadImageVkMailing', vkMessagesPhoto)
          vkMessagesPhotos.value = null
          newVkMessagesPhotos.value.value = ''
          form.classList.remove("was-validated")
          await getVkMessagesPhotos(props.vkBindingId)
        }
      }
    }

    const selectedImg = ref({
      id: null,
      url: null,
      ownerId: null,
      photoId: null,
      accessKey: null
    })

    const resetSelectedImg = () => {
      selectedImg.value.id = null
      selectedImg.value.url = null
      selectedImg.value.ownerId = null
      selectedImg.value.photoId = null
      selectedImg.value.accessKey = null
    }

    const checkImg = (id) => {
      if (id !== selectedImg.value.id) {
        selectedImg.value.id = id
        listVkMessagesPhoto.value.vkMessagesPhotos.forEach(item => {
          if (item.id === id) {
            selectedImg.value.url = item.url
            selectedImg.value.ownerId = item.ownerId
            selectedImg.value.photoId = item.photoId
            selectedImg.value.accessKey = item.accessKey
          }
        })
      } else {
        resetSelectedImg()
      }
    }

    const uploadImageMailing = () => {
      const vkMessagePhoto = {
        id: props.letterImageId,
        url: selectedImg.value.url,
        ownerId: selectedImg.value.ownerId,
        photoId: selectedImg.value.photoId,
        accessKey: selectedImg.value.accessKey
      }
      emit('uploadImageVkMailing', vkMessagePhoto)
      resetSelectedImg()
    }

    const deleteVkMessagesPhoto = async () => {
      await deleteVkMessagesPhotos(props.vkBindingId, selectedImg.value.id)
      resetSelectedImg()
      page.value = 1
    }

    return {
      initUploadImageMailingModal,
      mailingTypes,
      newVkMessagesPhotos,
      selectedImg,
      checkImg,
      filter,
      page,
      mailingVkLoader,
      errorMessage,
      listVkMessagesPhoto,
      uploadImageMailing,
      imagesUpload,
      addVkMessagesPhoto,
      deleteVkMessagesPhoto,
      getVkMessagePhotos
    }
  }

}
</script>

<style scoped>

</style>